(function () {
  angular
    .module("akitabox.core.services.workOrder", [
      "akitabox.constants",
      "akitabox.core.services.attachment",
      "akitabox.core.services.building",
      "akitabox.core.services.http",
      "akitabox.core.services.recent",
      "akitabox.core.services.timeZone",
      "akitabox.core.services.virtualRepeat",
    ])
    .factory("WorkOrderService", WorkOrderService);

  /* @ngInject */
  function WorkOrderService(
    // Libraries
    $q,
    moment,
    // AkitaBox
    models,
    // Services
    AttachmentService,
    BuildingService,
    HttpService,
    FeatureFlagService,
    FilterService,
    RecentService,
    TimeZoneService,
    VirtualRepeatService
  ) {
    var service = {
      // Routes
      buildListRoute: buildListRoute,
      buildOrganizationListRoute: buildOrganizationListRoute,
      buildDetailRoute: buildDetailRoute,
      // Create
      create: create,
      createFromRequest: createFromRequest,
      // Retrieve
      getById: getById,
      getByBuildingId: getByBuildingId,
      getByOrganization: getByOrganization,
      getAllByBuildingId: getAllByBuildingId,
      getAllByOrganization: getAllByOrganization,
      getStatsByBuilding: getStatsByBuilding,
      getStatsByOrganization: getStatsByOrganization,
      getBFFIPByOrganization: getBFFIPByOrganization,
      // Update
      update: update,
      complete: complete,
      assign: assign,
      reopen: reopen,
      cancel: cancel,
      setRound: setRound,
      // Delete
      remove: remove,
      // Virtual Repeat
      getVirtualRepeat: getVirtualRepeat,
      getMyWorkOrderVirtualRepeat: getMyWorkOrderVirtualRepeat,
      // Filters
      getBuildingListFilter: getBuildingListFilter,
      getMyListFilter: getMyListFilter,
      setBuildingListFilter: setBuildingListFilter,
      setMyListFilter: setMyListFilter,
      getStatusQuery: getStatusQuery,
      // Permissions
      canLogWork: canLogWork,
      // Attachments
      getAttachments: getAttachments,
      // Utility
      getAsset: getAsset,
      getRoom: getRoom,
      getLevel: getLevel,
      getAssets: getAssets,
      getRooms: getRooms,
      getLevels: getLevels,
    };

    return service;

    // ------------------------
    //   Private Functions
    // ------------------------

    function buildBaseRoute(buildingId) {
      return "/buildings/" + buildingId + "/" + models.WORK_ORDER.ROUTE_PLURAL;
    }

    function buildDetailRoute(buildingId, orderId) {
      var base = buildBaseRoute(buildingId);
      return base + "/" + orderId;
    }

    function buildListRoute(buildingId) {
      return buildBaseRoute(buildingId);
    }

    function buildBFFBaseRoute(organizationId) {
      return `organizations/${organizationId}/web/work_orders`;
    }

    function buildOrganizationListRoute(organizationId) {
      return (
        "/organizations/" +
        organizationId +
        "/" +
        models.WORK_ORDER.ROUTE_PLURAL
      );
    }

    function getStatusQuery(params) {
      if (angular.isEmpty(params) || angular.isEmpty(params.status)) {
        return params;
      }

      // we don't want to edit the original params as this can break our logic later
      var paramCopy = angular.copy(params);

      var tomorrow = moment().utc().endOf("day");

      if (
        paramCopy.status === "$in,scheduled,open" ||
        paramCopy.status === "$in,open,scheduled"
      ) {
        paramCopy.status = "open";
      } else if (params.status === "scheduled") {
        paramCopy.status = "open";
        if (angular.isEmpty(paramCopy.start_date)) {
          paramCopy.start_date = "$gt," + tomorrow.valueOf();
        }
      } else if (
        paramCopy.status === "open" &&
        angular.isEmpty(paramCopy.start_date)
      ) {
        paramCopy.start_date = "$lte," + tomorrow.valueOf();
      }

      return paramCopy;
    }

    // ------------------------
    //   Public Functions
    // ------------------------

    /******************* *
     *   Create
     * ****************** */

    function create(buildingId, rawData, timeZone) {
      var data = angular.copy(rawData);
      var route = buildListRoute(buildingId);
      var woTimeFlagEnabled = FeatureFlagService.isEnabled(
        "work_order_date_time"
      );
      // Set date to be milliseconds to avoid confusion
      if (data.start_date) {
        data.start_date = new Date(data.start_date).valueOf();
      }
      // Force due_date to be at the end of the day to mimic the behavior we had before time zone support
      // This should be removed when we support WOs at arbitrary times
      if (data.due_date && woTimeFlagEnabled) {
        data.due_date = moment(data.due_date)
          .tz(timeZone || TimeZoneService.getCurrentTimeZone())
          .valueOf();
      } else if (data.due_date) {
        data.due_date = moment(data.due_date)
          .tz(timeZone || TimeZoneService.getCurrentTimeZone())
          .endOf("day")
          .valueOf();
      }
      return HttpService.post(route, data);
    }

    /**
     * Takes data from the service request (and its issue type's linked trade) and automatically creates a work order
     * from the existing data.
     *
     * @param {Building} building The building to create the work order in
     * @param {Request} request The request to create the work order from
     * @param {Trade} trade The request's issue type's linked trade (optional if no assignee is needed)
     */
    function createFromRequest(building, request, trade) {
      var data = {
        priority: "medium",
        estimated_man_hr: 1,
        description_text: request.description,
        subject: request.subject,
        start_date: moment().toDate(),
        due_date: moment().add(1, "days").toDate(), // due date is one day from today
        request: request._id,
      };

      // Attach the issue type if the building has issue types activated
      if (building.show_issue_types && request.issue_type) {
        data.issue_type = request.issue_type._id;
      }

      // Attach the trade if the building has trades activated and the request's issue type has a trade
      if (
        building.show_trades &&
        request.issue_type &&
        request.issue_type.trade
      ) {
        data.trade = request.issue_type.trade;
      }

      // Attach the default assignee if the trade is passed in and has one
      if (trade && trade.default_assignee_user) {
        data.assignee_users = [trade.default_assignee_user];
      }

      // Set location such that only one location is added to the round, and that it's the most granular one available
      if (request.level) {
        data.round = [{ level: request.level._id }];
      }
      if (request.room) {
        data.round = [{ room: request.room._id }];
      }
      if (request.asset) {
        data.round = [{ asset: request.asset._id }];
      }

      // attach request custom field if it exists
      if (request.custom_srp_value) {
        data.custom_srp_value = request.custom_srp_value;
      }
      // Call create
      return service.create(building._id, data);
    }

    /******************* *
     *   Retrieve
     ******************* */

    function getByBuildingId(buildingId, params) {
      var route = buildListRoute(buildingId);
      return HttpService.get(route, getStatusQuery(params));
    }

    function getByOrganization(organizationId, params) {
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.get(route, getStatusQuery(params));
    }

    function getAllByBuildingId(buildingId, params, useCache) {
      var route = buildListRoute(buildingId);
      return HttpService.getAll(route, getStatusQuery(params), useCache);
    }

    function getAllByOrganization(organizationId, params) {
      var route = buildOrganizationListRoute(organizationId);
      return HttpService.getAll(route, getStatusQuery(params));
    }

    function getById(buildingId, id, params) {
      var route = buildDetailRoute(buildingId, id);
      return HttpService.getById(route, id, params);
    }

    function getStatsByBuilding(buildingId, params) {
      var route = "/stats" + buildListRoute(buildingId);
      return HttpService.get(route, params);
    }

    function getStatsByOrganization(organizationId, params) {
      var route = "/stats" + buildOrganizationListRoute(organizationId);
      return HttpService.get(route, params);
    }

    function getBFFIPByOrganization(organizationId, params) {
      const route = buildBFFBaseRoute(organizationId);
      return HttpService.get(route, getStatusQuery(params));
    }

    /******************* *
     *   Update
     * ****************** */

    function update(buildingId, id, rawData) {
      var woTimeFlagEnabled = FeatureFlagService.isEnabled(
        "work_order_date_time"
      );
      var data = angular.copy(rawData);
      var route = buildDetailRoute(buildingId, id);
      // Set date to be milliseconds to avoid confusion
      if (data.start_date) {
        data.start_date = new Date(data.start_date).valueOf();
      }
      if (data.due_date) {
        if (woTimeFlagEnabled) {
          data.due_date = moment(data.due_date)
            .tz(TimeZoneService.getCurrentTimeZone())
            .valueOf();
        } else {
          // remove when "work_order_date_time" flag is removed
          data.due_date = moment(data.due_date)
            .tz(TimeZoneService.getCurrentTimeZone())
            .endOf("day")
            .valueOf();
        }
      }
      return HttpService.put(route, data);
    }

    function complete(buildingId, id, optionalData) {
      var data = {};
      if (!angular.isEmpty(optionalData)) {
        data = optionalData;
      }
      data.action = "complete";
      var route = buildDetailRoute(buildingId, id);
      return HttpService.patch(route, data);
    }

    /**
     * TODO: WEBAPP-11853 Implement this method. It should handle
     * incoming populated stops on the data.round field. Depopulate
     * them to just IDs if they come in populated.
     * @param { string } buildingId
     * @param { string } id
     * @param { object } data
     */
    function setRound(buildingId, id, data) {
      if (!data || !data.round || typeof data.round.length !== "number") {
        return $q.reject(
          new Error("data.round is not a valid array of rounds")
        );
      }
      var depopulatedRound = [];
      for (var i = 0; i < data.round.length; i++) {
        var stop = data.round[i];
        depopulatedRound.push(
          Object.assign({}, stop, {
            asset: stop.asset ? stop.asset._id || stop.asset : null,
            room: stop.room ? stop.room._id || stop.room : null,
            level: stop.level ? stop.level._id || stop.level : null,
          })
        );
      }
      return HttpService.patch(buildDetailRoute(buildingId, id), {
        action: "setLocation",
        round: depopulatedRound,
      });
    }

    function assign(buildingId, id, data) {
      data = angular.extend({}, data, { action: "assign" });
      var route = buildDetailRoute(buildingId, id);
      return HttpService.patch(route, data);
    }

    function reopen(buildingId, id) {
      var data = { action: "reopen" };
      var route = buildDetailRoute(buildingId, id);
      return HttpService.patch(route, data);
    }

    function cancel(buildingId, id) {
      var data = { action: "cancel" };
      var route = buildDetailRoute(buildingId, id);
      return HttpService.patch(route, data);
    }

    /******************* *
     *   Delete
     * ****************** */

    function remove(buildingId, id) {
      var route = buildDetailRoute(buildingId, id);
      return HttpService.remove(route).then(function (workOrder) {
        // need to clear the my work order cache...from the recent.service
        RecentService.clearCookieAt("tasks", id);
        return workOrder;
      });
    }

    /******************* *
     *   Virtual Repeat
     * ****************** */

    function getVirtualRepeat(organizationId, fetchLimit, query) {
      return VirtualRepeatService.create(fetch, fetchLimit);

      function fetch(skip, limit) {
        if (!query) {
          query = {};
        }

        var params = angular.extend(query, {
          skip: skip,
          limit: limit,
        });

        return service.getBFFIPByOrganization(organizationId, params);
      }
    }

    function getMyWorkOrderVirtualRepeat(fetchLimit, query, orgId) {
      var buildings = {};

      return VirtualRepeatService.create(fetch, fetchLimit);

      function fetch(skip, limit) {
        if (!query) {
          query = {};
        }

        var params = angular.extend(query, {
          skip: skip,
          limit: limit,
        });

        return service
          .getBFFIPByOrganization(orgId, params)
          .then(function (workOrders) {
            // Populates the buildings variable with the buildings in each WO
            return BuildingService.populateBuildings(
              buildings,
              workOrders
            ).then(function () {
              // finally we return each WO with a populated building object
              // By this point the buildings object should have been populated by the .populatedBuildings() already
              return workOrders.map(function (workOrder) {
                // If we don't have the name property in workOrder, then we need to set the building property in each workOrder
                // with its corresponding building data
                if (!workOrder.building?.name) {
                  const buildingId =
                    workOrder.building._id || workOrder.building;
                  workOrder.building = buildings[buildingId];
                }
                return workOrder;
              });
            });
          });
      }
    }

    /******************* *
     *   Filters
     * ****************** */

    /**
     * Retrieve cached building work order list filter
     *
     * @param  {String} buildingId
     * @return {Object}
     */
    function getBuildingListFilter(buildingId) {
      return FilterService.getBuildingFilter(buildingId, "task");
    }

    /**
     * Retrieve cached user work order list filter
     *
     * @return {Object}
     */
    function getMyListFilter() {
      return FilterService.getUserFilter("task");
    }

    /**
     * Cache the provided building work order list filter
     *
     * @param {String} buildingId
     * @param {Object} filter
     */
    function setBuildingListFilter(buildingId, filter) {
      FilterService.setBuildingFilter(buildingId, "task", filter);
    }

    /**
     * Cache the provided user work order list filter
     *
     * @param {Object} filter
     */
    function setMyListFilter(filter) {
      FilterService.setUserFilter("task", filter);
    }

    /**
     * THIS IS A NEW PERMISSIONS FUNCTION. DO NOT REMOVE WHEN REMOVING LEGACY FUNCTIONALITY.
     * Given a user (with permisions) and a work order, returns a boolean indicating whether or not the user can log
     * work on this work order
     * @param {Task} workOrder
     * @param {User} user
     * @returns {Boolean} whether or not the user can log work on this work order
     */
    function canLogWork(workOrder, user) {
      var permissions = user.permission_group;
      var isOwn = workOrder.assignee_users.includes(user._id);

      var canLogOnAny =
        permissions.work_order_log.create_own_log_on_any_task ||
        permissions.work_order_log.create_any;

      return isOwn
        ? // Logging for self should always be enabled
          permissions.work_order_log.create_own_log_on_own_task
        : // For tasks we're not assigned to:
          canLogOnAny;
    }

    /******************* *
     *   Attachments
     * ****************** */

    function getAttachments(buildingId, orderId, params) {
      var requiredParams = {
        entity_id: orderId,
        entity_type: "task",
      };

      params = Object.assign({}, params, requiredParams);

      return AttachmentService.getAll(buildingId, params);
    }

    /******************* *
     *   Utilitiy
     * ****************** */

    /**
     * Grabs the asset from the work order. Built to work with how we
     * handle locations currently and with the upcoming round changes
     * @param {Task} workOrder
     * @return {Asset|null}
     */
    function getAsset(workOrder) {
      if (!workOrder.round || workOrder.round.length !== 1) {
        //bff workorder
        if (
          workOrder.first_stop &&
          workOrder.first_stop.asset &&
          workOrder.first_stop.asset.name
        ) {
          return workOrder.first_stop.asset;
        }
        return null;
      }
      var round = workOrder.round[0];
      if (round.asset) return round.asset;
      return null;
    }

    /**
     * Grabs the asset from the work order. Built to work with how we
     * handle locations currently and with the upcoming round changes
     * @param {Task} workOrder
     * @return {Room|null}
     */
    function getRoom(workOrder) {
      if (!workOrder.round || workOrder.round.length !== 1) {
        //bff workorder
        if (
          workOrder.first_stop &&
          workOrder.first_stop.room &&
          workOrder.first_stop.room.name
        ) {
          return {
            ...workOrder.first_stop.room,
            display_name: `${workOrder.first_stop.room.number} - ${workOrder.first_stop.room.name}`,
          };
        }
        return null;
      }
      var round = workOrder.round[0];
      if (round.asset) return round.asset.room || null;
      return round.room || null;
    }

    /**
     * Grabs the asset from the work order. Built to work with how we
     * handle locations currently and with the upcoming round changes
     * @param {Task} workOrder
     * @return {Level|null}
     */
    function getLevel(workOrder) {
      if (
        workOrder.first_stop &&
        workOrder.first_stop.level &&
        workOrder.first_stop.level.name
      ) {
        return workOrder.first_stop.level;
      } else if (workOrder.round && workOrder.round.length) {
        var round = workOrder.round[0];
        if (round.asset) return round.asset.level || null;
        else if (round.room) return round.room.level || null;
        return round.level || null;
      } else {
        return null;
      }
    }

    /**
     * Returns an array of the assets that exist in this work order's round
     * @param {Task} workOrder
     * @return {Array[Asset]}
     */
    function getAssets(workOrder) {
      var workOrderAssets = [];
      for (var i = 0; i < workOrder.round.length; ++i) {
        var stop = workOrder.round[i];
        if (stop.asset) {
          workOrderAssets.push(stop.asset);
        }
      }

      return workOrderAssets;
    }

    /**
     * Returns an array of the rooms that exist in this work order's round
     * @param {Task} workOrder
     * @return {Array[Room]}
     */
    function getRooms(workOrder) {
      var workOrderRooms = [];
      for (var i = 0; i < workOrder.round.length; ++i) {
        var stop = workOrder.round[i];
        var room;
        if (stop.asset) {
          room = stop.asset.room;
        } else {
          room = stop.room;
        }

        if (room && findInArray(workOrderRooms, room) === -1) {
          workOrderRooms.push(room);
        }
      }

      return workOrderRooms.sort((a, b) => {
        return sortingWithNumbers(a.display_name, b.display_name);
      });
    }

    function sortingWithNumbers(a, b) {
      const aNumbers = a.match(/(\d+)/g);
      const bNumbers = b.match(/(\d+)/g);
      if (!aNumbers || !bNumbers) {
        if (aNumbers) return -1;
        return 1;
      }

      if (aNumbers.length === 1 && bNumbers.length === 1) {
        return Number(aNumbers[0]) - Number(bNumbers[0]);
      }
      const maxLength =
        aNumbers.length > bNumbers.length ? aNumbers.length : bNumbers.length;

      for (let index = 0; index < maxLength; index++) {
        const aNumber = Number(aNumbers[index]);
        const bNumber = Number(bNumbers[index]);
        if (aNumber && bNumber) {
          if (aNumber === bNumber) {
            continue;
          }
          return aNumber - bNumber;
        } else if (aNumber) return aNumber;
        else if (bNumber) return bNumber;
      }
    }

    /**
     * Returns an array of the levels that exist in this work order's round
     * @param {Task} workOrder
     * @return {Array[Level]}
     */
    function getLevels(workOrder) {
      const isBffRoute = workOrder.first_stop;
      if (isBffRoute) {
        return [workOrder.first_stop.level];
      } else {
        var workOrderFloors = [];
        for (var i = 0; i < workOrder.round.length; ++i) {
          var stop = workOrder.round[i];
          var floor;
          if (stop.asset) {
            floor = stop.asset.level;
          } else if (stop.room) {
            floor = stop.room.level;
          } else {
            floor = stop.level;
          }

          if (floor && findInArray(workOrderFloors, floor) === -1) {
            workOrderFloors.push(floor);
          }
        }

        return workOrderFloors.sort((a, b) => {
          return sortingWithNumbers(a.name, b.name);
        });
      }
    }

    function findInArray(array, item) {
      if (!item) {
        return -1;
      }
      for (var i = 0; i < array.length; i++) {
        var currentModel = array[i];
        if (currentModel._id === item._id) {
          return i;
        }
      }

      return -1;
    }
  }
})();
